import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getStripKey, updateStripKey } from "../api/api";
import { ErrorParser, SuccessParser } from "../api/helper";
import Button from "../atoms/Button";
import Container from "../atoms/Container";
import Flex from "../atoms/Flex";
import Input from "../atoms/Input";
import Label from "../atoms/Label";
import Title from "../atoms/Title";
import CommonLayout from "../layout/CommonLayout";

const Stripe = () => {
  const initialField = {
    public_key: "",
    private_key: "",
  };
  const navigate = useNavigate();
  const [field, setField] = useState(initialField);

  const fetchData = async () => {
    const resp = await getStripKey();
    handleSetUpdateState(resp.data.stripe_key);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSetUpdateState = (data) => {
    Object.keys(initialField).forEach((key) => {
      if (key in data) {
        initialField[key] = data[key];
      }
    });
    setField({ ...initialField });
  };

  const handleChange = (e) =>
    setField({ ...field, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      stripe_key: {
        public_key: field.public_key,
        private_key: field.private_key,
      },
    };
    const resp = await updateStripKey(data);
    ErrorParser(resp);
    if (resp.status) {
      SuccessParser("Stripe Key Updated!");
      navigate("/");
    }
  };
  return (
    <CommonLayout>
      <Container direction="column">
        <Wrapper
          alignItems="center"
          justifyContent="center"
          fullWidth
          direction="column"
        >
          <form onSubmit={onSubmit}>
            <Form fullWidth direction="column">
              <Title>Stripe</Title>
              <Registration fullWidth direction="column">
                <Flex fullWidth direction="column">
                  <Label>Public Key</Label>
                  <Input
                    type="text"
                    placeholder="Enter Public Key"
                    name="public_key"
                    value={field.public_key}
                    onChange={handleChange}
                    required
                  />
                </Flex>
                <Flex fullWidth direction="column">
                  <Label>Private Key</Label>
                  <Input
                    type="text"
                    placeholder="Enter Private Key"
                    name="private_key"
                    value={field.private_key}
                    onChange={handleChange}
                    required
                  />
                </Flex>
                <ButtonWrap
                  alignItems="center"
                  justifyContent="flex-end"
                  fullWidth
                >
                  <Input type="submit" value="Update" Button />
                  <CancelButton onClick={() => navigate("/")}>
                    Cancel
                  </CancelButton>
                </ButtonWrap>
              </Registration>
            </Form>
          </form>
        </Wrapper>
      </Container>
    </CommonLayout>
  );
};

export default Stripe;

const CancelButton = styled(Button)`
  background-color: #dbdbdb;
  color: #333333;
  width: 100px;
  border-radius: 4px;
  &:hover {
    background: #e8eae6;
  }
`;

const CreateButton = styled(Button)`
  width: 100px;
  border-radius: 4px;
`;

const ButtonWrap = styled(Flex)`
  gap: 10px;
  margin-top: 10px;
`;

const Registration = styled(Flex)`
  margin-top: 40px;
  gap: 10px;
`;

const Form = styled(Flex)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%);
  width: 680px;
  height: auto;
  padding: 57px 65px;
`;

const Wrapper = styled(Flex)`
  margin-top: 100px;
`;
