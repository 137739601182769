import styled, { css } from "styled-components";

const Input = styled.input`
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 4px;
  ${({ Button, fullButton }) =>
    Button &&
    css`
      width: ${fullButton ? "100%" : "100px"};
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      margin: 0;
      background: ${(p) => p.theme.colors.btn};
      color: ${(p) => p.theme.colors.secondary};
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      &:hover {
        background: ${(p) => p.theme.colors.btnHover};
      }
      &:disabled {
        cursor: not-allowed;
        background: ${(p) => p.theme.colors.btnHover};
      }
    `}
`;

export default Input;
