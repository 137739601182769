import React from "react";
import styled, { css } from "styled-components";
import { useTable, usePagination } from "react-table";
import Flex from "./Flex";
import StyledButton from "./Button";

function Table({
  columns,
  data,
  Header,
  showPayButton,
  HeaderProps,
  nextPage,
  previousPage,
  currentPage,
  totalPage,
  gotoFirstPage,
  gotoLastPage,
  limit,
  changeLimit,
  dataPerPage,
  total,
  handleOpenForAll
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 25 },
    },
    usePagination
  );

  return (
    <TableWrapper>
      {Header && <Header {...HeaderProps} />}
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups?.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <TableHeader {...column.getHeaderProps()}>
                  {column.render("Header")}
                </TableHeader>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
      {total && <Pagination>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
          <p>Total Amount</p>
          <p style={{ paddingRight: "20px" }}>{total}</p>
        </div>
      </Pagination>}
      <Pagination>
        <div>
          <Button onClick={() => gotoFirstPage()} disabled={currentPage === 1}>
            {"<<"}
          </Button>{" "}
          <Button onClick={() => previousPage()} disabled={currentPage === 1}>
            {"<"}
          </Button>{" "}
          <Button
            onClick={() => nextPage()}
            disabled={currentPage === totalPage}
          >
            {">"}
          </Button>{" "}
          <Button
            onClick={() => gotoLastPage()}
            disabled={currentPage === totalPage}
          >
            {">>"}
          </Button>{" "}
        </div>
        <div>
          Page {currentPage} of {totalPage}
        </div>
        <div>
          <select
            value={limit}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              changeLimit(Number(e.target.value));
            }}
          >
            {dataPerPage.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          {showPayButton && <PayAllBtn onClick={() => handleOpenForAll()} >Pay All</PayAllBtn>}
        </div>
      </Pagination>
    </TableWrapper>
  );
}

function TableComp({
  columns,
  data,
  Header,
  showPayButton,
  HeaderProps,
  pageCount,
  nextPage,
  currentPage,
  totalPage,
  previousPage,
  gotoFirstPage,
  gotoLastPage,
  limit,
  changeLimit,
  dataPerPage,
  total,
  handleOpenForAll
}) {
  return (
    <Table
      columns={columns}
      data={data?.data}
      Header={Header}
      nextPage={nextPage}
      showPayButton={showPayButton}
      HeaderProps={HeaderProps}
      pageCount={pageCount}
      totalPage={totalPage}
      currentPage={currentPage}
      gotoFirstPage={gotoFirstPage}
      gotoLastPage={gotoLastPage}
      previousPage={previousPage}
      limit={limit}
      changeLimit={changeLimit}
      dataPerPage={dataPerPage}
      total={total}
      handleOpenForAll={handleOpenForAll}
    />
  );
}

export default TableComp;

const TableWrapper = styled.div`
  margin-top: 40px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  width: calc(100% - 40px);
  margin-bottom: 20px;
`;
const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0px;
  tr {
    &:last-child {
      td {
        border: none;
      }
    }
  }
  th {
    text-align: left;
    color: black;
    font-weight: 400;
    padding: 10px 0px 10px 0px;
    text-align: center;
    border-bottom: 1px solid #dbdbdb;
  }
  td {
    font-size: 16px;
    font-weight: 500;
    padding: 20px 10px 20px 0px;
    text-align: center;
    color: grey;
    border-bottom: 1px solid #dbdbdb;
  }
`;

const TableHeader = styled.th`
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset;
`;

const Pagination = styled(Flex)`
  border-top: 1px solid #dbdbdb;
  padding: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  input {
    width: 150px;
    border: 1px solid #dbdbdb;
    padding: 5px 16px;
    border-radius: 4px;
  }
  select {
    width: 110px;
    padding: 10px 8px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    cursor: pointer;
  }
`;

const PayAllBtn = styled(StyledButton)`
  color: #fff !important;
  width: 100px !important;
  height: 45px !important;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 0 16px;
  height: 25px;
  border: 1px solid #dbdbdb;
  color: #fff;
  background-color: ${(p) => p.theme.colors.btn};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${(p) => p.theme.colors.btnHover};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #dbdbdb;
      &:hover {
        background-color: #dbdbdb;
        cursor: default;
      }
    `}
`;
