import toastr from "toastr";

toastr.options = {
  positionClass: "toast-bottom-right",
  hideDuration: 300,
  timeOut: 3000,
  closeButton: true,
  showMethod: "slideDown",
  hideMethod: "slideUp",
  closeMethod: "slideUp",
  progressBar: true,
};
toastr.clear();

export const ErrorParser = (resp) => {
  if (resp.status === false) toastr.error(resp.message);
  if (resp?.errors?.length > 0) resp.errors.map((k) => toastr.error(k.msg));
};

export const SuccessParser = (msg) => toastr.success(msg);
