import Container from "../../atoms/Container";
import CommonLayout from "../../layout/CommonLayout";
import styled from "styled-components";
import Table from "../../atoms/Table";
import { useMemo, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import ReportHeader from "./Report.table.header";
import { Cookies } from "react-cookie";
import { getUserTimeSheet } from "../../api/api";

const Report = () => {
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const user = jwt_decode(token);
  const id = user._id;
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotalPage] = useState();
  const [limit, setLimit] = useState(10);
  const [datas, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async (page, limit) => {
    setPage(page);
    const resp = await getUserTimeSheet({ id, limit, page });
    setTotalPage(Math.ceil(resp.total / resp.perpage));
    let temp = [];
    if (resp.status) {
      temp.data = resp.data.map((el) => {
        return {
          ad_name: el.ad_name,
          begin_play_time: el.begin_play_time,
          device_name: el.device_name,
          duration: el.duration,
          latitude: el.location.latitude,
          longitude: el.location.longitude,
        };
      });
      temp = {
        ...temp,
        total: resp.total,
      };
      setData(temp);
      setLoading(false);
    }
  };

  const nextPage = () => {
    fetchData(page + 1, limit);
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    fetchData(page - 1, limit);
    setCurrentPage(currentPage - 1);
  };

  const gotoFirstPage = () => {
    fetchData(1, limit);
    setCurrentPage(1);
  };

  const gotoLastPage = () => {
    fetchData(total, limit);
    setCurrentPage(total);
  };

  const changeLimit = (limit) => {
    setLimit(limit);
    fetchData(page, limit);
  };

  useEffect(() => {
    fetchData(1, limit);
  }, []);

  const columns = useMemo(
    () => [
      {
        accessor: "ad_name",
        Header: "Ad Name",
      },
      {
        accessor: "begin_play_time",
        Header: "Logged Seconds",
      },
      {
        accessor: "device_name",
        Header: "Device Name",
      },
      {
        accessor: "duration",
        Header: "Duration",
      },
      {
        accessor: "latitude",
        Header: "Latitude",
      },
      {
        accessor: "longitude",
        Header: "Longitude",
      },
    ],
    []
  );
  if (loading) return null;
  return (
    <CommonLayout>
      <StyledContainer>
        <Table
          data={datas}
          columns={columns}
          Header={ReportHeader}
          hasFileUpload
          pageCount={page}
          currentPage={currentPage}
          totalPage={total}
          previousPage={previousPage}
          gotoFirstPage={gotoFirstPage}
          gotoLastPage={gotoLastPage}
          limit={limit}
          nextPage={nextPage}
          changeLimit={changeLimit}
          dataPerPage={[10, 20, 25, 100]}
        />
      </StyledContainer>
    </CommonLayout>
  );
};

export default Report;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;
