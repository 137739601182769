import styled from "styled-components";
import Flex from "../../atoms/Flex";
import TableHeading from "../../atoms/TableHeading";

const TransactionHeader = () => {
  return (
    <ActionHeader fullWidth justifyContent="space-between" alignItems="center">
      <TableHeading>Transactions List</TableHeading>
    </ActionHeader>
  );
};

export default TransactionHeader;

const ActionHeader = styled(Flex)`
  margin-bottom: 20px;
`;
