import { WithGeneral } from "../../context/GeneralContext";
import { Navigate, useNavigate } from "react-router-dom";
import CommonLayout from "../../layout/CommonLayout";
import Container from "../../atoms/Container";
import styled from "styled-components";
import TableHeading from "../../atoms/TableHeading";
import Flex from "../../atoms/Flex";
import { useEffect, useState } from "react";
import { driverDashboardData } from "../../api/api";

const Dashboard = () => {
  const {
    data: { isAuth, isAdmin },
  } = WithGeneral();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const fetchData = async () => {
    const response = await driverDashboardData();
    setData(response.data);
  };

  useEffect(() => {
    if (!isAdmin) fetchData();
  }, []);

  if (isAuth && isAdmin) {
    return <Navigate to={"/admin-dashboard"} />;
  }
  return (
    <CommonLayout>
      <Container>
        <Main fullWidth>
          <StyledFlex fullWidth direction="column">
            <StyledTableHeading> Analytics</StyledTableHeading>
            <Flex direction="row" fullWidth style={{ display: "flex", gap: "10px", flexFlow: "wrap" }}>
              <Flex direction="column" className="mainCol">
                <SubHeading>Hours Driven</SubHeading>
                <CardWrapper fullWidth>
                  <Card
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => navigate("/timesheet ")}
                  >
                    <Title>Total Hours Driven</Title>
                    <Amount>{data?.noOfHoursDriven}</Amount>
                  </Card>
                </CardWrapper>
              </Flex>
              <Flex direction="column" className="mainCol">
                <SubHeading>Payment Received</SubHeading>
                <CardWrapper fullWidth>
                  <Card
                    direction="column"
                    onClick={() => navigate("/driver-transactions")}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Title>Total Payment Received</Title>
                    <Amount>${data?.totalPaidPayment}</Amount>
                  </Card>
                </CardWrapper>
              </Flex>
              <Flex direction="column" className="mainCol">
                <SubHeading>Payment Pending</SubHeading>
                <CardWrapper fullWidth>
                  <Card
                    direction="column"
                    onClick={() => navigate("/transactions")}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Title>Total Payment Pending</Title>
                    <Amount>${data?.totalPendingPayment}</Amount>
                  </Card>
                </CardWrapper>
              </Flex>
              <Flex direction="column" className="mainCol">
                <SubHeading>Total Payment</SubHeading>
                <CardWrapper fullWidth>
                  <Card
                    direction="column"
                    onClick={() => navigate("/transactions")}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Title>Total Payment </Title>
                    <Amount>${data?.TotalPayment}</Amount>
                  </Card>
                </CardWrapper>
              </Flex>
            </Flex>
          </StyledFlex>
        </Main>
      </Container>
    </CommonLayout >
  );
};

export default Dashboard;

const StyledFlex = styled(Flex)`
  gap: 25px;
`;

const SubHeading = styled.div`
  margin-bottom: 10px;
`;

const CardWrapper = styled(Flex)`
  gap: 20px;
`;

const StyledTableHeading = styled(TableHeading)`
  margin-bottom: 25px;
  @media(max-width: 991px){
    font-size: 24px;
  }
`;

const Amount = styled.div`
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  padding: 0 16px;
  @media(max-width: 991px){
    font-size: 22px;
  }
`;

const Title = styled.div`
  color: #14141f;
  font-size: 20px;
  padding: 0 16px;
  @media(max-width: 991px){
    font-size: 18px;
  }
`;

const Card = styled(Flex)`
  width: 100%;
  height: 100px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 0;
  gap: 16px;
  cursor: pointer;
`;

const Main = styled(Flex)`
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 25px;
  .mainCol{
    width: 24%;
    margin-bottom: 10px;
    @media(max-width: 1439px){
      width: 32.33%;
    }
    @media(max-width: 991px){
      width: 49%;
    }
    @media(max-width: 560px){
      width: 100%;
    }
  }
`;
