import styled, { css } from "styled-components";

const Button = styled.button`
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: ${(p) => p.theme.colors.btn};
  width: 100%;
  border: 0;
  padding: 15px;
  color: ${(p) => p.theme.colors.secondary};
  font-size: 14px;
  font-weight: 600;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  &:hover {
    background: ${(p) => p.theme.colors.btnHover};
  }
  &:disabled {
    cursor: not-allowed;
    &:hover {
      background: ${(p) => p.theme.colors.btn};
    }
  }
`;
export const CreateButton = styled(Button)`
  width: 100px;
  border-radius: 4px;
  ${({ small }) =>
    small &&
    css`
      width: 90px;
    `}
`;

export const CancelButton = styled(Button)`
  background-color: #dbdbdb;
  color: #333333;
  width: 100px;
  border-radius: 4px;
  &:hover {
    background: #e8eae6;
  }
  ${({ small }) =>
    small &&
    css`
      width: 90px;
    `}
`;
export default Button;
