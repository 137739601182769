import Section from '../atoms/Section'
import styled, { css } from 'styled-components'
import Flex from '../atoms/Flex'
import { useState, useRef } from 'react'
import HamBurger from '../atoms/HamBurger'
import { useLocation, useNavigate } from 'react-router-dom'
import { WithGeneral } from '../context/GeneralContext'
import useOutsideClick from '@rooks/use-outside-click'
import { useCookies } from 'react-cookie'
import { SuccessParser } from '../api/helper'
import * as Icon from 'react-feather'
import Icons from '../atoms/Icon'

const CommonLayout = ({ children }) => {
  const [Open, setOpen] = useState(false)
  const menuRef = useRef(null)
  const location = useLocation()
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name'])
  const {
    data: { isAdmin },
    setData: authData,
  } = WithGeneral()
  const activePath = location.pathname
  const AdminItems = [
    { title: 'Transactions', Link: '/transactions', Icon: <Icon.Layers /> },
    { title: 'Drivers', Link: '/drivers', Icon: <Icon.Users /> },
    { title: 'Timesheet', Link: '/admin-timesheet', Icon: <Icon.Watch /> },
    { title: 'Stripe', Link: '/stripe', Icon: <Icon.Key /> },
    {
      title: 'Cost Per Hour',
      Link: '/cost-per-hour',
      Icon: <Icon.DollarSign />,
    },
  ]
  const DriverItems = [
    {
      title: 'Payment Detail',
      Link: '/payment-detail',
      Icon: <Icon.DollarSign />,
    },
    {
      title: 'Transactions',
      Link: '/driver-transactions',
      Icon: <Icon.Layers />,
    },
    { title: 'Time Sheet', Link: '/timesheet', Icon: <Icon.Watch /> },
    { title: 'Account', Link: '/account', Icon: <Icon.User /> },
  ]

  const Logout = () => {
    authData({
      isAuth: false,
      isAdmin: false,
    })
    SuccessParser('Logout SuccessFully!')
    removeCookie('accessToken')
    removeCookie('refreshToken')
    removeCookie('isAdmin')
  }

  const navigate = useNavigate()
  const MenuItem = isAdmin ? AdminItems : DriverItems
  const handleMenu = () => setOpen(false)

  useOutsideClick(menuRef, handleMenu)
  return (
    <Flex>
      <StyledSection>
        <SideBar>
          <LogoWrapper onClick={() => navigate('/')}>
            <img src='/logo.png' alt='' />
          </LogoWrapper>
          <MenuWrapper
            onClick={() => setOpen(!Open)}
            direction='column'
            Open={Open}
            ref={menuRef}
          >
            <HamBurger />
            <HamBurger />
            <HamBurger />
          </MenuWrapper>
        </SideBar>
        {children}
      </StyledSection>
      <StyledSidebar Open={Open}>
        <Header alignItems='center'>
          <LogoWrapper onClick={() => navigate('/')}>
            <img src='/logo.png' alt='' />
          </LogoWrapper>
        </Header>
        <Content>
          {MenuItem.map((item, key) => {
            return (
              <Items
                key={key}
                onClick={() => navigate(item.Link)}
                active={activePath === item.Link}
              >
                <Icons style={{ display: 'flex', alignItems: 'center' }}>
                  {item.Icon} <MenuTitle>{item.title}</MenuTitle>
                </Icons>
              </Items>
            )
          })}
          <Items
            onClick={Logout}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {' '}
            <Icon.LogOut />
            <MenuTitle>Log Out</MenuTitle>
          </Items>
        </Content>
      </StyledSidebar>
    </Flex>
  )
}

export default CommonLayout

const LogoWrapper = styled(Flex)`
  cursor: pointer;
  img {
    height: 50px;
  }
`

const Items = styled.div`
  padding: 10px;
  cursor: pointer;
  letter-spacing: 1px;
  line-height: 1.4;
  border-radius: 10px;
  margin-top: 5px;
  &:hover {
    background-image: linear-gradient(98deg, #c6a7fe, #ff00f9 94%);
    color: #fff;
  }
  ${({ active }) =>
    active &&
    css`
      background-image: linear-gradient(98deg, #c6a7fe, #ff00f9 94%);
      color: white;
    `}
`

const Content = styled.div`
  padding: 16px;
`

const Header = styled(Flex)`
  padding: 0 20px;
  height: 64px;
  border-bottom: 1px solid #ededed;
`

const StyledSection = styled(Section)`
  display: flex;
  flex-direction: column;
`

const SideBar = styled(Flex)`
  height: 64px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
`

const MenuWrapper = styled(Flex)`
  gap: 5px;
  cursor: pointer;
  ${({ Open }) =>
    Open &&
    css`
      div {
        transform-origin: center;
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 0px;
          position: relative;
          background-color: #1d1e22;
        }
        &:nth-child(2) {
          display: none;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          bottom: 8px;
          position: relative;
          background-color: #1d1e22;
        }
      }
    `}
`

const StyledSidebar = styled.div`
  position: absolute;
  height: 100vh;
  width: 200px;
  left: ${({ Open }) => (Open ? '0%' : '-200px')};
  top: 0;
  background-color: white;
  transition: 0.2s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
`

const MenuTitle = styled.div`
  margin-left: 10px;
`
