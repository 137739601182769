import CommonLayout from '../../layout/CommonLayout'
import Container from '../../atoms/Container'
import Flex from '../../atoms/Flex'
import styled from 'styled-components'
import Title from '../../atoms/Title'
import Label from '../../atoms/Label'
import Input from '../../atoms/Input'
import { CancelButton } from '../../atoms/Button'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import CountryCode from '../login/CountryCode'
import { Cookies } from 'react-cookie'
import { getProfileData, updateProfileData } from '../../api/api'
import { ErrorParser, SuccessParser } from '../../api/helper'

const Account = () => {
  const cookies = new Cookies()
  const token = cookies.get('accessToken')
  const user = jwt_decode(token)
  const userId = user._id
  const fetchData = async () => {
    const resp = await getProfileData({ userId: userId })
    handleSetUpdateState(resp.data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  const initialField = {
    full_name: '',
    device_id: '132456',
    hourly_cost: '',
    mobile_number: '',
    country_code: 1,
  }

  const navigate = useNavigate()
  const [field, setField] = useState(initialField)
  const handleChange = (e) =>
    setField({ ...field, [e.target.name]: e.target.value })

  const handleSetUpdateState = (data) => {
    Object.keys(initialField).forEach((key) => {
      if (key in data) {
        if (key === 'mobile_number') {
          initialField['mobile_number'] = data.mobile_number.number
          initialField['country_code'] = data.mobile_number.country_code
        } else initialField[key] = data[key]
      }
    })
    setField({ ...initialField })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = {
      full_name: field.full_name,
      device_id: field.device_id,
      // hourly_cost: parseInt(field.hourly_cost),
      mobile_number: {
        country_code: parseInt(field.country_code),
        number: parseInt(field.mobile_number),
      },
    }
    const resp = await updateProfileData({ data })
    ErrorParser(resp)
    if (resp.status) SuccessParser('Profile Updated !')
  }

  return (
    <CommonLayout>
      <Container>
        <Wrapper alignItems='center' justifyContent='center' fullWidth>
          <form onSubmit={onSubmit}>
            <Form fullWidth direction='column'>
              <Title>Account</Title>
              <Registration fullWidth direction='column'>
                <Flex fullWidth direction='column'>
                  <Label>Full Name</Label>
                  <Input
                    type='text'
                    placeholder='Enter Full Name'
                    name='full_name'
                    onChange={handleChange}
                    value={field.full_name}
                    required
                  />
                </Flex>
                <Flex fullWidth direction='column'>
                  <StyledFlex fullWidth>
                    <Flex direction='column' fullWidth>
                      <Label>Device Id</Label>
                      <Input
                        type='text'
                        placeholder='Device ID'
                        name='device_id'
                        disabled
                        value={field.device_id}
                        required
                      />
                    </Flex>
                    {/* <Flex direction="column" fullWidth>
                      <Label>Hourly Cost</Label>
                      <PhoneInput
                        type="number"
                        placeholder="Hourly Cost"
                        name="hourly_cost"
                        value={field.hourly_cost}
                        required
                        disabled
                      />
                    </Flex> */}
                  </StyledFlex>
                </Flex>
                <Label>Phone Number</Label>
                <PhoneWrapper fullWidth direction='row'>
                  <Select name='country_code' onChange={handleChange} required>
                    <option>Code</option>
                    {CountryCode.map((item, key) => (
                      <option
                        key={key}
                        value={item.value}
                        selected={field.country_code == item.value}
                      >
                        + {item.value}
                      </option>
                    ))}
                  </Select>
                  <PhoneInput
                    type='Number'
                    placeholder='Phone Number'
                    name='mobile_number'
                    value={field.mobile_number}
                    onChange={handleChange}
                    required
                  />
                </PhoneWrapper>
                <ButtonWrap
                  alignItems='center'
                  justifyContent='flex-end'
                  fullWidth
                >
                  <Input type='submit' value='Update' Button />
                  <CancelButton onClick={() => navigate('/')}>
                    Cancel
                  </CancelButton>
                </ButtonWrap>
              </Registration>
            </Form>
          </form>
        </Wrapper>
      </Container>
    </CommonLayout>
  )
}

export default Account

const ButtonWrap = styled(Flex)`
  gap: 10px;
  margin-top: 10px;
`

const PhoneWrapper = styled(Flex)`
  gap: 10px;
`

const PhoneInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const StyledFlex = styled(Flex)`
  gap: 10px;
`

const Form = styled(Flex)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%);
  width: 680px;
  height: auto;
  padding: 57px 65px;
`

const Registration = styled(Flex)`
  margin-top: 40px;
  gap: 10px;
`

const Wrapper = styled(Flex)`
  margin-top: 100px;
`

const Select = styled.select`
  border: none;
  cursor: pointer;
  height: 46px;
  background: #f2f2f2;
  border-radius: 4px;
`
