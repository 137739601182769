import styled from "styled-components";
import Flex from "../../atoms/Flex";
import TableHeading from "../../atoms/TableHeading";

const ReportHeader = () => {
  return (
    <ActionHeader fullWidth justifyContent="space-between" alignItems="center">
      <TableHeading>Timesheet</TableHeading>
    </ActionHeader>
  );
};

export default ReportHeader;

const ActionHeader = styled(Flex)`
  margin-bottom: 20px;
`;
