import Table from "../../atoms/Table";
import { useMemo, useState, useEffect } from "react";
import Button from "../../atoms/Button";
import styled from "styled-components";
import PaymentDueHeader from "./Transaction.table.header";
import { getDriverPaidTransaction } from "../../api/api";
import CommonLayout from "../../layout/CommonLayout";
import PaymentDue from "../payment-due";
import Container from "../../atoms/Container";

const DriverTransaction = () => {
  const [data, setData] = useState({
    data: [],
    total: 0
  });
  const [driverData, setDriverData] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState('');

  const columns = useMemo(
    () => [
      {
        accessor: "id",
        Header: "ID",
        Cell: ({ cell }) => (
          <p>{cell.row.original.id}</p>
        )
      },
      {
        accessor: "currency",
        Header: "Currency",
        Cell: ({ cell }) => (
          <p>{cell.row.original.currency}</p>
        )
      },
      {
        accessor: "balance_transaction",
        Header: "Balance Transaction",
        Cell: ({ cell }) => (
          <p>{cell.row.original.balance_transaction}</p>
        )
      },
      {
        accessor: "date",
        Header: "Date",
        Cell: ({ cell }) => (
          <p>{new Date(cell?.value * 1000).toLocaleDateString("en-US")}</p>
        )
      },
      {
        accessor: "amount",
        Header: "Amount",
        Cell: ({ cell }) => (
          <p>${cell.row.original.paidTotalAmount}</p>
        )
      },
    ],
    []
  );

  useEffect(() => {
    getData(1, limit);
  }, []);

  const getData = async (page, limit) => {
    setPage(page);
    const resp = await getDriverPaidTransaction({ limit, page });
    setTotalPage(Math.ceil(resp.total / resp.perpage));
    let temp = [];
    if (resp.status) {
      temp.data = resp.data.map((el) => {
        return {
          date: el?.created,
          id: el?.id,
          currency: el?.currency,
          balance_transaction: el?.balance_transaction,
          paidTotalAmount: el?.amount / 100,
        };
      });
      temp = {
        ...temp,
        total: resp?.total,
      };
      setTotal(`$${resp?.totalAmount}`)
      setData(temp);
      setDriverData(resp)
      setLoading(false);
    }
  };

  const nextPage = () => {
    getData(page + 1, limit);
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    getData(page - 1, limit);
    setCurrentPage(currentPage - 1);
  };

  const gotoFirstPage = () => {
    getData(1, limit);
    setCurrentPage(1);
  };

  const gotoLastPage = () => {
    getData(totalPage, limit);
    setCurrentPage(totalPage);
  };

  const changeLimit = (limit) => {
    setLimit(limit);
    getData(page, limit);
  };

  return (
    <CommonLayout>
      <StyledContainer>
        <Table
          data={data}
          columns={columns}
          Header={PaymentDueHeader}
          HeaderProps={{ getData }}
          pageCount={page}
          currentPage={currentPage}
          totalPage={totalPage}
          previousPage={previousPage}
          gotoLastPage={gotoLastPage}
          gotoFirstPage={gotoFirstPage}
          limit={limit}
          nextPage={nextPage}
          changeLimit={changeLimit}
          dataPerPage={[10, 20, 25, 100]}
          total={total}
        />
        {/* <PaymentDue /> */}
      </StyledContainer>
    </CommonLayout>
  );
};


export default DriverTransaction;


const StyledButton = styled(Button)`
  border-radius: 4px;
  width: 100px;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;