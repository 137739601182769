import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { CancelButton, CreateButton } from "../../atoms/Button";
import Container from "../../atoms/Container";
import Flex from "../../atoms/Flex";
import Input from "../../atoms/Input";
import Label from "../../atoms/Label";
import Title from "../../atoms/Title";
import CommonLayout from "../../layout/CommonLayout";

const CreateReport = () => {
  const initalField = {
    date: "",
    loggedHours: null,
    rate: null,
  };
  const navigate = useNavigate();
  const [field, setField] = useState(initalField);
  const handleChange = (e) =>
    setField({ ...field, [e.target.name]: e.target.value });

  return (
    <CommonLayout>
      <Container direction="column">
        <Wrapper alignItems="center" justifyContent="center" fullWidth>
          <Form fullWidth direction="column">
            <Title>Create Report</Title>
            <Registration fullWidth direction="column">
              <Flex fullWidth direction="column">
                <Label>Date</Label>
                <Input
                  type="datetime-local"
                  placeholder="Enter Full Name"
                  name="date"
                  value={field.date}
                  onChange={handleChange}
                />
              </Flex>
              <Flex fullWidth direction="column">
                <Label>Logged Hours</Label>
                <StyledInput
                  type="number"
                  placeholder="Enter Logged Hours"
                  name="loggedHours"
                  value={field.loggedHours}
                  onChange={handleChange}
                />
              </Flex>
              <Flex fullWidth direction="column">
                <Label>Rate</Label>
                <StyledInput
                  type="number"
                  placeholder="Enter Rate"
                  name="rate"
                  value={field.rate}
                  onChange={handleChange}
                />
              </Flex>
              <StyledFlex fullWidth show={field.loggedHours && field.rate}>
                <Label>Total Amount: </Label>
                <div>{+field.loggedHours * +field.rate}</div>
              </StyledFlex>
              <ButtonWrap
                alignItems="center"
                justifyContent="flex-end"
                fullWidth
              >
                <CreateButton onClick={() => navigate("/report")}>
                  Create
                </CreateButton>
                <CancelButton onClick={() => navigate("/report")}>
                  Cancel
                </CancelButton>
              </ButtonWrap>
            </Registration>
          </Form>
        </Wrapper>
      </Container>
    </CommonLayout>
  );
};

export default CreateReport;

const StyledFlex = styled(Flex)`
  visibility: hidden;
  ${({ show }) =>
    show &&
    css`
      visibility: visible;
    `}
`;

const ButtonWrap = styled(Flex)`
  gap: 10px;
  margin-top: 10px;
`;

const StyledInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Registration = styled(Flex)`
  margin-top: 40px;
  gap: 10px;
`;

const Form = styled(Flex)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%);
  width: 680px;
  height: auto;
  padding: 57px 65px;
`;

const Wrapper = styled(Flex)`
  margin-top: 100px;
`;
