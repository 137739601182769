import styled, { css } from "styled-components";

const Title = styled.h1`
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  color: #ff00f9;
  margin: 0;
  ${(p) =>
    p.secondary &&
    css`
      color: ${p.theme.colors.secondary};
    `}
`;

export default Title;
