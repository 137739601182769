import styled from "styled-components";

const TableHeading = styled.h3`
  color: #343434;
  text-transform: capitalize;
  font-weight: 600;
  margin: 0;
  font-size: 30px;
`;

export default TableHeading;
