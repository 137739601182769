import styled from "styled-components";
import Input from "../../atoms/Input";
import Section from "../../atoms/Section";
import { Navigate, useNavigate } from "react-router-dom";
import { WithGeneral } from "../../context/GeneralContext";
import Flex from "../../atoms/Flex";
import Title from "../../atoms/Title";
import CountryCode from "./CountryCode"
import { useState } from "react";
import { sendOTPforSignup, verifyOTPforSginup } from "../../api/api";
import { useCookies } from "react-cookie";
import { ErrorParser, SuccessParser } from "../../api/helper";
import Label from "../../atoms/Label";

const DriverSignup = () => {
  const navigate = useNavigate()
  const initialField = {
    full_name: "",
    device_id: "",
    hourly_cost: 0,
    country_code: 1,
    mobile_number: "",
  };
  const [field, setField] = useState(initialField);
  const {
    data: { isAuth },
    setData: authData,
  } = WithGeneral();
  const [verify, setVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState();
  const [cookies, setCookie] = useCookies(["token"]);

  if (isAuth) return <Navigate to="/" />;

  const onChangeOTP = (e) => setOtp(e.target.value);

  const handleChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      full_name: field.full_name,
      device_id: field.device_id,
      hourly_cost: field.hourly_cost,
      mobile_number: {
        country_code: parseInt(field.country_code),
        number: parseInt(field.mobile_number),
      },
    };
    setLoading(true);
    try {
      const resp = await sendOTPforSignup({
        mobile_number: {
          country_code: parseInt(field.country_code),
          number: parseInt(field.mobile_number),
        },
      });
      ErrorParser(resp);
      if (resp.status) {
        SuccessParser(resp?.message);
        setVerify(true);
      }
    } catch (error) {
      console.log("Error ", error);
    }
    setLoading(false);
  };


  const verifyLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      full_name: field.full_name,
      device_id: field.device_id,
      hourly_cost: field.hourly_cost,
      mobile_number: {
        country_code: parseInt(field.country_code),
        number: parseInt(field.mobile_number),
      },
      otp: otp
    };
    const resp = await verifyOTPforSginup(data);
    ErrorParser(resp);
    if (resp?.data?.token) {
      SuccessParser(resp.message);
      setCookie("accessToken", resp.data.token.accessToken);
      setCookie("refreshToken", resp.data.token.refreshToken);
      authData({
        isAuth: true,
        isAdmin: false,
      });
    }
    setLoading(false);
  };

  if (isAuth) return <Navigate to="/" />;

  return (
    <Section>
      <Box>
        <LogoWrapper justifyContent="center">
          <img src="/logo.png" alt="" />
        </LogoWrapper>
        <LoginHeader> Welcome to BUMPR! 👋🏻</LoginHeader>
        {!verify && (<form onSubmit={onSubmit} method="post">
          <Registration fullWidth direction="column">
            <Flex fullWidth direction="column">
              <Label>Full Name</Label>
              <Input
                type="text"
                placeholder="Enter Full Name"
                name="full_name"
                value={field.full_name}
                onChange={handleChange}
                required
              />
            </Flex>
              <Flex direction="column" fullWidth>
                <Label>Device Id</Label>
                <Input
                  type="text"
                  placeholder="Device ID"
                  name="device_id"
                  value={field.device_id}
                  onChange={handleChange}
                  required
                />
              </Flex>
            <Label>Phone Number</Label>
            <PhoneWrapper fullWidth direction="row">
              <Select name="country_code" required>
                <option>Code</option>
                {CountryCode.map((item, key) => (
                  <option key={key} value={item.value} selected={field.country_code == item.value}>
                    + {item.value}
                  </option>
                ))}
              </Select>
              <PhoneInput
                type="Number"
                placeholder="Phone Number"
                name="mobile_number"
                value={field.mobile_number}
                onChange={handleChange}
                required
              />
            </PhoneWrapper>
            <Input
              type="submit"
              disabled={loading}
              value="Sign Up"
              Button
              fullButton
            />
            <Flex style={{ margin: "20px auto 0px auto" }}>
              <Label style={{ marginRight: "5px" }}>Already have an account?</Label>

              <Label onClick={() => navigate("/login")} style={{ color: "#aa00a6", cursor: "pointer" }} >Login</Label>
            </Flex>
          </Registration>
        </form>
        )}
        {verify && (
          <form onSubmit={verifyLogin}>
            <Wrapper alignItems="baseline" >
              <Registration fullWidth>
                <Flex direction="column" fullWidth>
                  <Label>OTP</Label>
                  <PhoneInput
                    name="otp"
                    type="number"
                    onChange={onChangeOTP}
                    placeholder="OTP"
                    required
                  />
                </Flex>
              </Registration>
            </Wrapper>
            <Input
              type="submit"
              Button
              disabled={loading}
              value="Verify"
              fullButton
            />
          </form>
        )}
      </Box>
    </Section>
  );
};

export default DriverSignup;

const LogoWrapper = styled(Flex)`
  margin-bottom: 30px;
  img {
    height: 50px;
  }
`;

const PhoneInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PhoneWrapper = styled(Flex)`
  gap: 10px;
`;

const Select = styled.select`
  border: none;
  cursor: pointer;
  height: 42px;
  background: #f2f2f2;
  border-radius: 4px;
`;

const Wrapper = styled(Flex)`
  width: 100%;
  gap: 10px;
`;

const Box = styled.div`
  background: #ffffff;
  min-width: 448px;
  padding: 45px;
  text-align: center;
  box-shadow: 0px 2px 10px 0px rgb(58 53 65 / 10%);
  border-radius: 8px;
  margin: auto;
  input[type="submit"]{
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
  @media(max-width: 599px){
    min-width: initial;
    padding: 45px 20px;
    max-width: 83%;
  }
`;


const StyledFlex = styled(Flex)`
  gap: 10px;
`;

const Registration = styled(Flex)`
  margin-top: 40px;
  gap: 10px;
`;

const FormWrap = styled(Flex)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%);
  width: 680px;
  height: auto;
  padding: 57px 65px;
`;

const LoginHeader = styled.label`
  font-size: 24px;
  color: #3a3541de;
  font-weight: 600;
  text-transform: uppercase;
`