import React, { useContext, createContext, useState } from "react";
import { Cookies } from "react-cookie";

const GeneralContext = createContext();
export const Provider = GeneralContext.Provider;
export const GeneralConsumer = GeneralContext.Consumer;

const GeneralProvider = ({ children }) => {
  const cookies = new Cookies();
  const [data, setData] = useState({
    isAuth: !!cookies.get("accessToken"),
    isAdmin: !!cookies.get("isAdmin"),
  });

  return (
    <Provider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </Provider>
  );
};

export const WithGeneral = () => useContext(GeneralContext);

export default GeneralProvider;
