import React, { useState, useMemo, useEffect } from "react";
import styled, { css } from "styled-components";
import Button, { CreateButton } from "../../atoms/Button";
import Container from "../../atoms/Container";
import Flex from "../../atoms/Flex";
import CommonLayout from "../../layout/CommonLayout";
import { createExpressAccountToStripeofDriver, getExpressAccountDashboardLoginLink, getExpressAccountToStripeofDriver } from "../../api/api";
import { ErrorParser } from "../../api/helper";

const Payment = () => {
  const [showButton, setShowButton] = useState(true);
  const [data, setData] = useState([]);

  const paymentLink = async (e) => {
    const form = await createExpressAccountToStripeofDriver()
    if (form?.status) {
      window.open(form?.data, '_blank');
    }
  }

  const getExpressAccount = async () => {
    const account = await getExpressAccountToStripeofDriver()
    if (account?.status) {
      setShowButton(false)
      setData(account?.data)
    }
    ErrorParser(account);
  }

  const expressDashboardLoginLink = async () => {
    const link = await getExpressAccountDashboardLoginLink()
    if (link?.status) {
      window.open(link?.data, '_blank');
    }
  }

  useEffect(() => {
    getExpressAccount()
  }, []);

  return (
    <CommonLayout>
      <Wrapper direction="column" style={{ alignItems: "center" }}>
        {showButton ?
          <Wrapper alignItems="center" justifyContent="center" fullWidth>
            <CreateButton style={{ width: "fit-content", margin: "50px 15px" }} onClick={paymentLink} > Connect Express Account</CreateButton>
          </Wrapper> :
          <>
            <Wrapper alignItems="center" justifyContent="center" fullWidth>
              <CreateButton style={{ width: "fit-content", margin: "50px 15px" }} onClick={expressDashboardLoginLink} >View Stripe Dashboard</CreateButton>
            </Wrapper>
            <Table>
              <tbody>
                <tr>
                  <Th colSpan={2} style={{ textAlign: "center", fontSize: "16px", textTransform: "uppercase" }}>Bank Details</Th>
                </tr>
                <tr>
                  <Th>Id</Th>
                  <Td>{data?.id}</Td>
                </tr>
                {data?.email && <tr>
                  <Th>Email</Th>
                  <Td>{data?.email}</Td>
                </tr>}
                <tr>
                  <Th>Bank Name </Th>
                  <Td>{data?.external_accounts?.data[0]?.bank_name}</Td>
                </tr>
                <tr>
                  <Th>Account Number</Th>
                  <Td>**** **** {data?.external_accounts?.data[0]?.last4}</Td>
                </tr>
                <tr>
                  <Th>Routing Number</Th>
                  <Td>{data?.external_accounts?.data[0]?.routing_number}</Td>
                </tr>
                <tr>
                  <Th>Currenecy</Th>
                  <Td>{data?.external_accounts?.data[0]?.currency}</Td>
                </tr>
                <tr>
                  <Th>Country</Th>
                  <Td>{data?.country}</Td>
                </tr>
              </tbody>
            </Table>
          </>
        }
      </Wrapper >
    </CommonLayout >
  );
};

export default Payment;

const Wrapper = styled(Flex)`
`;


const StyledButton = styled(Button)`
  border-radius: 4px;
  width: 100px;
  ${({ left }) =>
    left &&
    css`
      margin-left: 10px;
    `}
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Table = styled.table`
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 50%;
  tr:nth-child(even){
    background-color: #ddd;
    padding: 2px 10px;
  }
`;

const Td = styled.td`
text-align: left;
padding: 14px;
`;

const Th = styled.th`
text-align: left;
padding: 14px;
`;