import { Cookies } from 'react-cookie'

const cookies = new Cookies()

export const loginAdmin = async (email, password) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/admin/login`,
    fetchOptions
  )
  return response.json()
}

export const sendOTPforSignup = async (data) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/drivers/signup/otp-sent`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.json()
    return text
  }
  return response.json()
}

export const verifyOTPforSginup = async (data) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/drivers/signup/otp-verify`,
    fetchOptions
  )
  return response.json()
}

export const sendOTPforLogin = async (mobileNumber) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      mobile_number: {
        number: mobileNumber.number,
      },
    }),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/drivers/login/otp-sent`,
    fetchOptions
  )
  return response.json()
}

export const verifyOTPforLogin = async (mobileNumber, otp) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      mobile_number: {
        number: mobileNumber.number,
      },
      otp: otp,
    }),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/drivers/verify/otp-verify`,
    fetchOptions
  )
  return response.json()
}

// Create Driver

export const createDriver = async (data) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/driver`,
    fetchOptions
  )
  return response.json()
}

// getDrivers

export const getDrivers = async ({ limit, page }) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/driver?page=${page}&limit=${limit}`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const deleteDriver = async (id) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/driver/${id}`,
    fetchOptions
  )
  return response.json()
}

export const uploadDriver = async (file) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
    },
    body: file,
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/driver/import`,
    fetchOptions
  )
  return response.json()
}

export const getOneDriver = async (id) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/driver/${id}`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const updateDriver = async (id, data) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/driver/${id}`,
    fetchOptions
  )
  return response.json()
}

// TimeSheet Data for admin
export const getTimeSheet = async ({ limit, page }) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/timesheet?page=${page}&limit=${limit}`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const updateTimesheet = async (id, data) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/timesheet/${id}`,
    fetchOptions
  )
  return response.json()
}

export const uploadTimeSheet = async (file) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
    },
    body: file,
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/timesheet/import`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    return text
  }
  return response.json()
}

export const deleteTimeSheet = async (id) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/timesheet/${id}`,
    fetchOptions
  )

  return response.json()
}

export const createTimeSheet = async (data) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/timesheet`,
    fetchOptions
  )
  return response.json()
}

export const getOneTimeSheet = async (id) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/timesheet/${id}`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const getStripKey = async () => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/stripe/`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const updateStripKey = async (data) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/stripe/`,
    fetchOptions
  )
  return response.json()
}

export const getCostPerHour = async () => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/driver/cost-per-hour/`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const updateCostPerHour = async (data) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/driver/cost-per-hour/`,
    fetchOptions
  )
  return response.json()
}

export const adminDashboardData = async () => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/dashboard/admin`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const driverDashboardData = async () => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/dashboard/driver`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const getProfileData = async ({ userId }) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/driver/fetch/${userId}`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const updateProfileData = async ({ data }) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/driver/profile/update`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const getUserTimeSheet = async ({ limit, page }) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/timesheet/driver/list?page=${page}&limit=${limit}`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const getCustomAccountToStripeofDriver = async () => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/stripe/driver/express`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const createExpressAccountToStripeofDriver = async () => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/stripe/driver/create`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.json()
    return text
  }
  return response.json()
}

export const getExpressAccountToStripeofDriver = async () => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/stripe/driver/fetch`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.json()
    return text
  }
  return response.json()
}

export const getExpressAccountDashboardLoginLink = async () => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/stripe/driver/express/dashboard/login`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.json()
    return text
  }
  return response.json()
}

export const getPaidTransactionsOfDriver = async () => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/transaction/driver/paid`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const getAdminPaidTransaction = async ({ limit, page }) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/transaction/admin/paid?page=${page}&limit=${limit}`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const getAdminPendingTransactions = async ({ limit, page }) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/transaction/admin/pending?page=${page}&limit=${limit}`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const getDriverPaidTransaction = async ({ limit, page }) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/transaction/driver/paid?page=${page}&limit=${limit}`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.text()
    throw new Error(text)
  }
  return response.json()
}

export const createPayment = async (id, data) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/stripe/driver/payment/${id}`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.json()
    return text
  }
  return response.json()
}

export const PaymentToAll = async (id, data) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${cookies.get('accessToken')}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/stripe/driver/payment`,
    fetchOptions
  )
  if (response.status !== 200) {
    const text = await response.json()
    return text
  }
  return response.json()
}
