import Table from "../../atoms/Table";
import { useMemo, useState, useEffect } from "react";
import Button from "../../atoms/Button";
import styled from "styled-components";
import PaymentDueHeader from "./Paymentdue.table.header";
import { createPayment, getAdminPendingTransactions, PaymentToAll } from "../../api/api";
import Modal from "../../atoms/Modal";
import { ErrorParser, SuccessParser } from "../../api/helper";

const PaymentDue = ({ getTransaction }) => {
  const [data, setData] = useState({
    data: [],
    total: 0
  });
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [Open, setOpen] = useState(false);
  const [driver, setDriver] = useState('');
  const [total, setTotal] = useState('');
  const [payType, setPayType] = useState('');

  const columns = useMemo(
    () => [
      {
        accessor: "full_name",
        Header: "Driver Name",
      },
      {
        accessor: "device_id",
        Header: "Device ID",
      },
      {
        accessor: "hourly_cost",
        Header: "Current Hourly Rate",
        Cell: ({ cell }) => (
          <p>${cell.row.original.hourly_cost}</p>
        )
      },
      {
        accessor: "logged_hours",
        Header: "Total Logged Hours",
        Cell: ({ cell }) => (
          <p>{cell.row.original.logged_Hours}</p>
        )
      },
      {
        accessor: "average_hourly_cost",
        Header: "Average Rate",
        Cell: ({ cell }) => (
          <p>${((cell.row.original.total /
            cell.row?.original.logged_Hours) ? (cell.row.original.total /
              cell.row?.original.logged_Hours) : 0).toFixed(2).replace(/(\.0+|0+)$/, '')
          }</p>
        )
      },
      {
        accessor: "total",
        Header: "Total Pending Paymemt",
        Cell: ({ cell }) => (
          <p>${cell.row?.original?.pendingTotalAmount}</p>
        )
      },
      {
        accessor: "pay",
        Header: "Pay Now",
        Cell: ({ cell }) => (
          <StyledButton value={cell.row.values.name} disabled={cell.row?.original?.pendingTotalAmount <= 0} onClick={() => handleOpen(cell)} >Pay</StyledButton>
        ),
      },
    ],
    []
  );
  useEffect(() => {
    getData(1, limit);
  }, []);

  const getData = async (page, limit) => {
    setPage(page);
    const resp = await getAdminPendingTransactions({ limit, page });
    setTotalPage(Math.ceil(resp.total / resp.perpage));
    let temp = [];
    if (resp.status) {
      temp.data = await resp.data.map((el) => {
        return {
          id: el?.driver_id,
          full_name: el?.full_name,
          device_id: el?.device_id,
          hourly_cost: el?.hourly_cost,
          paidTotalAmount: (Math.round(el?.paidTotalAmount * 100) / 100).toFixed(2),
          pendingTotalAmount: (Math.round(el?.pendingTotalAmount * 100) / 100).toFixed(2),
          total: el?.total,
          logged_Hours: (el?.timesheets / 3600).toFixed(2)
        };
      });
      temp = {
        ...temp,
        total: resp?.total,
      };
      setTotal(`$${(resp?.data?.reduce(function (prev, cur) { return prev + cur.pendingTotalAmount }, 0)).toFixed(2)}`)
      setData(temp);
      setLoading(false);
    }
  };

  const nextPage = () => {
    getData(page + 1, limit);
    setCurrentPage(currentPage + 1);
  };
  const previousPage = () => {
    getData(page - 1, limit);
    setCurrentPage(currentPage - 1);
  };

  const gotoFirstPage = () => {
    getData(1, limit);
    setCurrentPage(1);
  };

  const gotoLastPage = () => {
    getData(totalPage, limit);
    setCurrentPage(totalPage);
  };

  const changeLimit = (limit) => {
    setLimit(limit);
    setPage(1)
    getData(page, limit);
  };

  const handleOpen = (cell) => {
    setDriver(cell?.row?.original)
    setPayType("payToDriver")
    setOpen(true);
  };

  const handleOpenForAll = () => {
    setPayType("payToAll")
    setOpen(true);
  };

  const onClose = () => setOpen(false);

  const handlePay = async () => {
    const payment = await createPayment(driver.id, { amount: (driver?.pendingTotalAmount) });
    ErrorParser(payment);
    if (payment.status) SuccessParser("Payment Proceed Successfully");
    getTransaction(page, limit)
    getData(page, limit)
    setPayType('')
    setDriver('')
    setOpen(false);
  };

  const handlePayToAll = async () => {
    const payment = await PaymentToAll();
    ErrorParser(payment);
    if (payment.status) SuccessParser(payment?.message);
    getTransaction(page, limit)
    getData(page, limit)
    setPayType('')
    setOpen(false);
  }



  return (
    <>
      <Table
        data={data}
        columns={columns}
        Header={PaymentDueHeader}
        HeaderProps={{ getData }}
        pageCount={page}
        currentPage={currentPage}
        totalPage={totalPage}
        previousPage={previousPage}
        gotoLastPage={gotoLastPage}
        gotoFirstPage={gotoFirstPage}
        limit={limit}
        nextPage={nextPage}
        changeLimit={changeLimit}
        dataPerPage={[10, 25, 50, 100]}
        showPayButton
        total={total}
        handleOpenForAll={handleOpenForAll}
      />
      {
        payType === "payToDriver" ?

          <Modal
            onClose={onClose}
            onClick={handlePay}
            title="Payment to Driver"
            subTitle={`Are you sure you want to pay ${driver.full_name}?`}
            btnName="Pay"
            Open={Open}
          />
          :
          <Modal
            onClose={onClose}
            onClick={handlePayToAll}
            title="Payment to All Driver"
            subTitle={`Are you sure you want to pay all?`}
            btnName="Pay All"
            Open={Open}
          />
      }
    </>
  );
};

export default PaymentDue;

const StyledButton = styled(Button)`
  border-radius: 4px;
  width: 100px;
`;