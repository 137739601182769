import React, { useEffect } from "react";
import styled from "styled-components";
import { CancelButton, CreateButton } from "./Button";
import Flex from "./Flex";
import { Bars } from "react-loader-spinner";

const Modal = (props) => {
  const { onClick, onClose, title, subTitle, btnName, Open, loader } = props;
  useEffect(() => {
    Open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [Open]);
  return (
    <Warp alignItems="center" fullWidth justifyContent="center" Open={Open}>
      <Container direction="column">
        <ModalBody>
          <Head direction="column">
            <Header>{title}</Header>
            <SubHeader>{subTitle}</SubHeader>
          </Head>
          <Footer justifyContent="flex-end">
            <ButtonAction alignItems="center">
              <CreateButton small onClick={onClick} disabled={loader}>
                {
                  loader ? 
                  <Bars
                    height="30"
                    width="50"
                    color="#fff"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  /> : 
                btnName
                }
              </CreateButton>
              <CancelButton small onClick={onClose}>
                Cancel
              </CancelButton>
            </ButtonAction>
          </Footer>
        </ModalBody>
      </Container>
    </Warp>
  );
};

export default Modal;

const ButtonAction = styled(Flex)`
  gap: 10px;
`;

const Footer = styled(Flex)`
  padding: 20px;
  border-top: 1px solid #ececed;
`;

const Head = styled(Flex)`
  padding: 32px;
  gap: 16px;
`;

const SubHeader = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: #4c4c55;
`;

const Header = styled.div`
  color: #14141f;
  font-size: 24px;
  line-height: 30px;
`;

const ModalBody = styled.div`
  background-color: #fff;
  width: 420px;
  border-radius: 16px;
`;

const Container = styled(Flex)`
  background-color: transparent;
`;

const Warp = styled(Flex)`
  min-height: 100vh;
  position: fixed;
  inset: 0;
  background-color: rgba(52, 60, 73, 0.5);
  display: ${({ Open }) => (Open ? "flex" : "none")};
`;
