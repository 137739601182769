import styled from "styled-components";
import Flex from "../../atoms/Flex";
import TableHeading from "../../atoms/TableHeading";

const PaymentDueHeader = () => {
  return (
    <ActionHeader fullWidht justifyContent="space-between" alignItems="center">
      <TableHeading>Payment Due List</TableHeading>
    </ActionHeader>
  );
};

export default PaymentDueHeader;

const ActionHeader = styled(Flex)`
  margin-bottom: 20px;
`;
