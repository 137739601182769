import styled from "styled-components";
import Button from "../../atoms/Button";
import Flex from "../../atoms/Flex";
import TableHeading from "../../atoms/TableHeading";
import { useState, useRef } from "react";
import Modal from "../../atoms/Modal";
import { uploadTimeSheet } from "../../api/api";
import { useNavigate } from "react-router-dom";
import * as Icon from 'react-feather';
import { SuccessParser } from "../../api/helper";

const TimesheetHeader = ({ getData }) => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [Open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);

  const inputRef = useRef();

  const triggerInput = () => {
    inputRef.current.click();
  };

  const handleFile = (e) => {
    setFile(e?.target?.files[0]);
    if (e?.target?.files[0]) setOpen(true);
  };

  const fileUpload = async () => {
    setLoader(true)
    const formData = new FormData();
    formData.append("timesheet", file);
    const response = await uploadTimeSheet(formData);
    if (response.status) {
      setLoader(false)
      SuccessParser("File Upload Successfully!");
      getData(1, 25);
      setOpen(false);
      setFile(null);
    }
  };

  const onClose = () => {
    setFile(null);
    setOpen(false);
  };

  return (
    <>
      <ActionHeader
        fullWidht
        justifyContent="space-between"
        alignItems="center"
      >
        <TableHeading>Timesheet</TableHeading>
        <ButtonWrapper>
          <StyledButton onClick={() => navigate("/admin-timesheet/create")} style={{ width: 'fit-content' }} ><Icon.UserPlus />
            Create TimeSheet
          </StyledButton>
        <Input type="file" onChange={handleFile} ref={inputRef} />
          <StyledButton onClick={triggerInput} ><Icon.UploadCloud />Upload File</StyledButton>
        </ButtonWrapper>
      </ActionHeader>
      <Modal
        onClose={onClose}
        file={file}
        onClick={fileUpload}
        title="Upload TimeSheet"
        loader={loader}
        subTitle={`Are you sure you want to upload ${file?.name}?`}
        btnName="Upload"
        Open={Open}
      />
    </>
  );
};

export default TimesheetHeader;

const ButtonWrapper = styled(Flex)`
  gap: 10px;
`;

const Input = styled.input`
  display: none;
`;

const StyledButton = styled(Button)`
color: #fff;
width: 200px;
border-radius: 4px;
display: flex;
align-items: center;
justify-content: center;
svg{
  margin-right: 10px;
}
`;

const ActionHeader = styled(Flex)`
  margin-bottom: 20px;
`;