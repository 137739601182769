import styled from "styled-components";

const HamBurger = styled.div`
  height: 3px;
  width: 28px;
  background-color: #000;
  transform: translateY(-50%);
`;

export default HamBurger;
