import styled from "styled-components";
import Button from "../../atoms/Button";
import Flex from "../../atoms/Flex";
import TableHeading from "../../atoms/TableHeading";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../atoms/Modal";
import { uploadDriver } from "../../api/api";
import { ErrorParser, SuccessParser } from "../../api/helper";
import * as Icon from 'react-feather';

const DriverHeader = ({ getData }) => {
  const [file, setFile] = useState(null);
  const [Open, setOpen] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [loader, setLoader] = useState(false);

  const handleFile = (e) => {
    setFile(e.target.files[0]);
    if (e.target.files[0]) setOpen(true);
  };
  const handleClick = () => {
    inputRef?.current.click();
  };

  const fileUpload = async () => {
    const formData = new FormData();
    setLoader(true)
    formData.append("driver", file);
    const response = await uploadDriver(formData);
    ErrorParser(response);
    if (response.status) {
      setLoader(false)
      SuccessParser("File Uploaded Successfully!");
      setOpen(false);
      getData(1, 10);
      setFile(null);
    }
  };

  const onClose = () => {
    setFile(null);
    setOpen(false);
  };
  return (
    <>
      <ActionHeader
        fullWidht
        justifyContent="space-between"
        alignItems="center"
      >
        <TableHeading>Driver List</TableHeading>
        <Input type="file" onChange={handleFile} ref={inputRef} />
        <ButtonWrapper>
          <StyledButton onClick={() => navigate("/drivers/create")}><Icon.UserPlus /> Create Driver</StyledButton>
          <StyledButton onClick={handleClick} style={{ display: "flex", flexDirection: "row" }} >
            <Icon.UploadCloud />Upload File
          </StyledButton>
        </ButtonWrapper>
      </ActionHeader>
      <Modal
        onClose={onClose}
        file={file}
        Open={Open}
        loader={loader}
        onClick={fileUpload}
        title="Upload Driver"
        subTitle={`Are you sure you want to upload ${file?.name}?`}
        btnName="Upload"
      />
    </>
  );
};

export default DriverHeader;

const ButtonWrapper = styled(Flex)`
  gap: 10px;
`;

const Input = styled.input`
  display: none;
`;

const StyledButton = styled(Button)`
  color: #fff;
  width: 200px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    margin-right: 10px;
  }
`;

const ActionHeader = styled(Flex)`
  margin-bottom: 20px;
`;
