import styled, { css } from "styled-components";

const Flex = styled.div`
  display: flex;
  flex-direction: ${(p) => p.direction || "row"};
  align-items: ${(p) => p.alignItems || "flex-start"};
  justify-content: ${(p) => p.justifyContent || "flex-start"};
  flex-wrap: ${(p) => p.wrap || "nowrap"};
  ${(p) =>
    p.fullWidth &&
    css`
      width: 100%;
    `}
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.7;
      pointer-events: none;
      cursor: not-allowed;
    `}
`;

export default Flex;
