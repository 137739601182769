import Table from "../../atoms/Table";
import { useMemo, useState, useEffect } from "react";
import Button from "../../atoms/Button";
import styled from "styled-components";
import PaymentDueHeader from "./Transaction.table.header";
import { getAdminPaidTransaction } from "../../api/api";
import CommonLayout from "../../layout/CommonLayout";
import PaymentDue from "../payment-due";
import Container from "../../atoms/Container";
import * as Icon from 'react-feather';

const Transaction = () => {
  const [data, setData] = useState({
    data: [],
    total: 0
  });
  const [driverData, setDriverData] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [totalPage, setTotalPage] = useState();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState('');
  const [select, setSelect] = useState('paidTransaction');

  const columns = useMemo(
    () => [
      {
        accessor: "full_name",
        Header: "Driver Name",
      },
      {
        accessor: "device_id",
        Header: "Device ID",
      },
      {
        accessor: "hourly_cost",
        Header: "Current Hourly Rate",
        Cell: ({ cell }) => (
          <p>${cell.row.original.hourly_cost}</p>
        )
      },
      {
        accessor: "logged_hours",
        Header: "Total Logged Hours",
        Cell: ({ cell }) => (
          <p>{cell.row.original.logged_Hours}</p>
        )
      },
      {
        accessor: "average_hourly_cost",
        Header: "Average Rate",
        Cell: ({ cell }) => (
          <p>${((cell.row.original.total /
            cell.row?.original.logged_Hours) ? (cell.row.original.total /
              cell.row?.original.logged_Hours) : 0).toFixed(2).replace(/(\.0+|0+)$/, '')
          }</p>
        )
      },
      {
        accessor: "total",
        Header: "Total Paid Amount",
        Cell: ({ cell }) => (
          <p>${cell.row.original.paidTotalAmount}</p>
        )
      },
    ],
    []
  );

  useEffect(() => {
    getData(1, limit);
  }, []);

  const getData = async (page, limit) => {
    setPage(page);
    const resp = await getAdminPaidTransaction({ limit, page });
    setTotalPage(Math.ceil(resp.total / resp.perpage));
    let temp = [];
    if (resp.status) {
      temp.data = resp.data.map((el) => {
        return {
          id: el?._id,
          full_name: el?.full_name,
          device_id: el?.device_id,
          hourly_cost: el?.hourly_cost,
          paidTotalAmount: el?.paidTotalAmount,
          pendingTotalAmount: el?.pendingTotalAmount,
          total: ((el?.total * 100) / 100).toFixed(2),
          logged_Hours: (el?.totalLoggedHours/3600).toFixed(2)
        };
      });
      temp = {
        ...temp,
        total: resp?.total,
      };
      setTotal(resp?.totalAmount)
      setData(temp);
      setDriverData(resp)
      setLoading(false);
    }
  };
  const nextPage = () => {
    getData(page + 1, limit);
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    getData(page - 1, limit);
    setCurrentPage(currentPage - 1);
  };

  const gotoFirstPage = () => {
    getData(1, limit);
    setCurrentPage(1);
  };

  const gotoLastPage = () => {
    getData(totalPage, limit);
    setCurrentPage(totalPage);
  };

  const changeLimit = (limit) => {
    setLimit(limit);
    getData(page, limit);
  };

  return (
    <CommonLayout>
      <StyledContainer>
        <TabContainer className="tab">
          <StyledButton className="tablinks" onClick={() => setSelect('paidTransaction')}
            style={{ backgroundColor: select === 'paidTransaction' && "#ff00f9", color: select === 'paidTransaction' && "#fff" }} >
            <Icon.List /> Transaction
          </StyledButton>
          <StyledButton className="tablinks" onClick={() => setSelect('pendingTransaction')} style={{ marginLeft: "6px", backgroundColor: select === 'pendingTransaction' && "#ff00f9", color: select === 'pendingTransaction' && "#fff" }}>
            <Icon.FileText /> Payment Due List
          </StyledButton>
        </TabContainer>
        {select === 'paidTransaction' && <Table
          data={data}
          columns={columns}
          Header={PaymentDueHeader}
          HeaderProps={{ getData }}
          pageCount={page}
          currentPage={currentPage}
          totalPage={totalPage}
          previousPage={previousPage}
          gotoLastPage={gotoLastPage}
          gotoFirstPage={gotoFirstPage}
          limit={limit}
          nextPage={nextPage}
          changeLimit={changeLimit}
          dataPerPage={[10, 25, 50, 100]}
          total={total}
        />}
        {select === 'pendingTransaction' && <PaymentDue getTransaction={getData}  />}
      </StyledContainer>
    </CommonLayout>
  );
};

export default Transaction;

const StyledButton = styled(Button)`  
  color: #fff;
  width: 200px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg{
    margin-right: 10px;
  }
  background-color: inherit;
  color: black;
  outline: none;
  width: "fit-content";
  &:hover {
    background-color: #ff00f9;
    color: #fff;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

const TabContainer = styled(Container)`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 15px;
    margin-right: 6px;
    padding-bottom: 5px;
    border-bottom: 1px solid grey;
}
`;