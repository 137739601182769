import styled, { ThemeProvider } from 'styled-components'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom'
import AdminLogin from './components/login/AdminLogin'
import Dashboard from './components/dashboard'
import Drivers from './components/drivers'
import Transactions from './components/transactions'
import { Theme } from './theme/theme'
import Account from './components/account'
import Payment from './components/payment'
import GeneralProvider, { WithGeneral } from './context/GeneralContext'
import DriverLogin from './components/login/DriverLogin'
import Create from './components/drivers/Create'
import Stripe from './Stripe'
import Report from './components/Report'
import CreateReport from './components/Report/CreateReport'
import Timesheet from './components/timesheet'
import AdminDashboard from './components/admin-dashboard'
import CrateTimesheet from './components/timesheet/CrateTimesheet'
import DriverTransaction from './components/driverTransactions '
import 'toastr/build/toastr.min.css'
import DriverSignup from './components/login/DriverSignup'
import CostPerHour from './CostPerHour'

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <GeneralProvider>
        <NavigationRoutes />
      </GeneralProvider>
    </ThemeProvider>
  )
}

export default App

const NavigationRoutes = () => {
  const {
    data: { isAdmin, isAuth },
  } = WithGeneral()

  const DriverWrapper = () => {
    return !isAdmin ? <Outlet /> : <Navigate to='/' replace />
  }

  const AdminWrapper = () => {
    return isAdmin ? <Outlet /> : <Navigate to='/' replace />
  }

  const LoginWrapper = () => {
    return isAuth ? <Outlet /> : <Navigate to='/login' />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LoginWrapper />}>
          <Route index element={<Dashboard />} />
          <Route element={<DriverWrapper />}>
            <Route path='payment-detail' element={<Payment />} />
            <Route path='driver-transactions' element={<DriverTransaction />} />
            <Route path='timesheet' element={<Report />} />
            <Route path='timesheet/create' element={<CreateReport />} />
            <Route path='account' element={<Account />} />
          </Route>
          <Route element={<AdminWrapper />}>
            <Route path='admin-dashboard' element={<AdminDashboard />} />
            <Route path='transactions' element={<Transactions />} />
            <Route path='drivers' element={<Drivers />} />
            <Route path='drivers/create' element={<Create />} />
            <Route path='stripe' element={<Stripe />} />
            <Route path='cost-per-hour' element={<CostPerHour />} />
            <Route path='admin-timesheet' element={<Timesheet />} />
            <Route path='admin-timesheet/create' element={<CrateTimesheet />} />
          </Route>
        </Route>
        <Route path='*' element={<Title>No page</Title>} />
        <Route path='login' element={<DriverLogin />} />
        <Route path='signup' element={<DriverSignup />} />
        <Route path='admin-login' element={<AdminLogin />} />
      </Routes>
    </BrowserRouter>
  )
}

const Title = styled.h1`
  margin: 0;
`
