import styled from "styled-components";
import Input from "../../atoms/Input";
import Section from "../../atoms/Section";
import { Navigate } from "react-router-dom";
import { WithGeneral } from "../../context/GeneralContext";
import Flex from "../../atoms/Flex";
import { useState } from "react";
import { loginAdmin } from "../../api/api";
import { useCookies } from "react-cookie";
import { ErrorParser, SuccessParser } from "../../api/helper";
import Label from "../../atoms/Label";

const AdminLogin = () => {
  const {
    data: { isAuth },
    setData: authData,
  } = WithGeneral();
  const [loading, setLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["token"]);
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const changeData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await loginAdmin(data.email, data.password);
    ErrorParser(resp);
    if (resp?.data?.token) {
      SuccessParser("Admin Login Success");
      setCookie("accessToken", resp.data.token.accessToken);
      setCookie("refreshToken", resp.data.token.refreshToken);
      setCookie("isAdmin", true);
      authData({
        isAuth: true,
        isAdmin: true,
      });
    }
    setLoading(false);
  };

  if (isAuth) return <Navigate to="/" />;
  return (
    <Section fullWidth>
      <Box>
        <LogoWrapper justifyContent="center">
          <img src="/logo.png" alt="" />
        </LogoWrapper>
        <LoginHeader> Welcome to BUMPR! 👋🏻</LoginHeader>
        <form onSubmit={onSubmit}>
          <Login fullWidth direction="column">

            <Flex direction="column" fullWidth>
              <Label>Email</Label>
              <Input
                name="email"
                type="text"
                placeholder="Email"
                onChange={(e) => changeData(e)}
                required
              />
            </Flex>
            <Flex direction="column" fullWidth>
              <Label>Password</Label>
              <Input
                name="password"
                type="password"
                placeholder="password"
                onChange={(e) => changeData(e)}
                required
                autocomplete="off"
              />
            </Flex>
            <Input
              type="submit"
              Button
              disabled={loading}
              value="login"
              fullButton
            />
          </Login>
        </form>
      </Box>
    </Section>
  );
};

export default AdminLogin;

const LogoWrapper = styled(Flex)`
  margin-bottom: 30px;
  img {
    height: 50px;
  }
`;

const Login = styled(Flex)`
  margin-top: 40px;
  gap: 10px;
`;

const Box = styled.div`
  background: #ffffff;
  min-width: 448px;
  padding: 45px;
  text-align: center;
  box-shadow: 0px 2px 10px 0px rgb(58 53 65 / 10%);
  margin: auto;
  border-radius: 8px;
  input[type="submit"]{
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
  @media(max-width: 599px){
    min-width: initial;
    padding: 45px 20px;
    max-width: 83%;
  }
`;


const LoginHeader = styled.label`
  font-size: 24px;
  color: #3a3541de;
  font-weight: 600;
  text-transform: uppercase;
`
