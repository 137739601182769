import styled from "styled-components";
import Input from "../../atoms/Input";
import Section from "../../atoms/Section";
import { Navigate, useNavigate } from "react-router-dom";
import { WithGeneral } from "../../context/GeneralContext";
import Flex from "../../atoms/Flex";
import CountryCode from "./CountryCode";
import { useState } from "react";
import { sendOTPforLogin, verifyOTPforLogin } from "../../api/api";
import { useCookies } from "react-cookie";
import { ErrorParser, SuccessParser } from "../../api/helper";
import Label from "../../atoms/Label";

const DriverLogin = () => {
  const navigate = useNavigate()
  const [input, setInput] = useState({
    number: "",
  });
  const {
    data: { isAuth },
    setData: authData,
  } = WithGeneral();
  const [verify, setVerify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(false);
  const [cookies, setCookie] = useCookies(["token"]);
  if (isAuth) return <Navigate to="/" />;

  const onInputChange = (e) => {
    setInput({
      ...input,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const onChangeOTP = (e) => setOtp(e.target.value);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await sendOTPforLogin(input);
    ErrorParser(resp);
    if (resp?.status) {
      SuccessParser(resp?.message);
      setVerify(true);
    }
    setLoading(false);
  };

  const verifyLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await verifyOTPforLogin(input, otp);
    ErrorParser(resp);
    if (resp?.data?.token) {
      SuccessParser(resp?.message);
      setCookie("accessToken", resp.data.token.accessToken);
      setCookie("refreshToken", resp.data.token.refreshToken);
      authData({
        isAuth: true,
        isAdmin: false,
      });
    }
    setLoading(false);
  };

  if (isAuth) return <Navigate to="/" />;

  return (
    <Section>
      <Box>
        <LogoWrapper justifyContent="center">
          <img src="/logo.png" alt="" />
        </LogoWrapper>
        <LoginHeader> Welcome to BUMPR! 👋🏻</LoginHeader>
        {!verify && (
          <form onSubmit={onSubmit}>
            <Wrapper alignItems="baseline">
              <Flex direction="column" fullWidth>
                <Label>Phone Number</Label>
                <PhoneInput
                  name="number"
                  type="number"
                  onChange={(e) => onInputChange(e)}
                  placeholder="Phone Number"
                  required
                />
              </Flex>
            </Wrapper>
            <Input
              type="submit"
              disabled={loading}
              value="Send OTP"
              Button
              fullButton
            />
            <Flex style={{ margin: "20px 0 0px auto", flexFlow: "wrap", justifyContent: "center" }}>
              <Label style={{ marginRight: "5px" }}>Don't have an account?</Label>
              <Label onClick={() => navigate("/signup")} style={{ color: "#aa00a6", cursor: "pointer" }} >
                Create Account!
              </Label>
            </Flex>
          </form>
        )}
        {verify && (
          <form onSubmit={verifyLogin}>
            <Wrapper alignItems="baseline">
              <PhoneInput
                name="otp"
                type="number"
                onChange={onChangeOTP}
                placeholder="OTP"
                required
              />
            </Wrapper>
            <Input
              type="submit"
              Button
              disabled={loading}
              value="Verify"
              fullButton
            />
          </form>
        )}
      </Box>

    </Section >
  );
};

export default DriverLogin;

const LogoWrapper = styled(Flex)`
  margin-bottom: 30px;
  img {
    height: 50px;
  }
`;

const PhoneInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Select = styled.select`
  border: none;
  cursor: pointer;
  height: 42px;
  background: #f2f2f2;
  border-radius: 4px;
`;

const Wrapper = styled(Flex)`
  width: 100%;
  gap: 10px;
  margin: 40px 0 20px 0;
`;

const Box = styled.div`
  background: #ffffff;
  min-width: 448px; 
  padding: 45px;
  text-align: center;
  box-shadow: 0px 2px 10px 0px rgb(58 53 65 / 10%);
  border-radius: 8px;
  margin: auto;
  input[type="submit"]{
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
  }
  @media(max-width: 599px){
    min-width: initial;
    padding: 45px 20px;
    max-width: 83%;
  }
`;

const LoginHeader = styled.label`
  font-size: 24px;
  color: #3a3541de;
  font-weight: 600;
  text-transform: uppercase;
`