import styled from "styled-components";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  height: 100%;
`;

export default Section;
