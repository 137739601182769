export const Theme = {
  colors: {
    primary: "#FF00F9",
    secondary: "#ffffff",
    black: "#333333",
    background: "#f3f3f4",
    btn: "#ff00f9",
    btnHover: "#aa00a6",
  },
};
