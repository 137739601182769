import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  getCostPerHour,
  getStripKey,
  updateCostPerHour,
  updateStripKey,
} from '../api/api'
import { ErrorParser, SuccessParser } from '../api/helper'
import Button from '../atoms/Button'
import Container from '../atoms/Container'
import Flex from '../atoms/Flex'
import Input from '../atoms/Input'
import Label from '../atoms/Label'
import Title from '../atoms/Title'
import CommonLayout from '../layout/CommonLayout'

const CostPerHour = () => {
  const initialField = {
    normal_cost: '',
    rush_cost: '',
  }
  const navigate = useNavigate()
  const [field, setField] = useState(initialField)

  const fetchData = async () => {
    const resp = await getCostPerHour()
    handleSetUpdateState(resp.data.hour_rate)
  }
  useEffect(() => {
    fetchData()
  }, [])

  const handleSetUpdateState = (data) => {
    Object.keys(initialField).forEach((key) => {
      if (key in data) {
        initialField[key] = data[key]
      }
    })
    setField({ ...initialField })
  }

  const handleChange = (e) =>
    setField({ ...field, [e.target.name]: e.target.value })

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = {
      hour_rate: {
        normal_cost: field.normal_cost,
        rush_cost: field.rush_cost,
      },
    }
    const resp = await updateCostPerHour(data)
    ErrorParser(resp)
    if (resp.status) {
      SuccessParser('Cost per rate Updated!')
      navigate('/')
    }
  }

  console.log(field)
  return (
    <CommonLayout>
      <Container direction='column'>
        <Wrapper
          alignItems='center'
          justifyContent='center'
          fullWidth
          direction='column'
        >
          <form onSubmit={onSubmit}>
            <Form fullWidth direction='column'>
              <Title>Cost Per Hour</Title>
              <Registration fullWidth direction='column'>
                <Flex fullWidth direction='column'>
                  <Label>Normal Hour Rate</Label>
                  <Input
                    type='number'
                    placeholder='Enter Normal Hour Rate'
                    name='normal_cost'
                    value={field.normal_cost}
                    onChange={handleChange}
                    required
                  />
                </Flex>
                <Flex fullWidth direction='column'>
                  <Label>Rush Hour Rate</Label>
                  <Input
                    type='number'
                    placeholder='Enter Rush Hour Rate'
                    name='rush_cost'
                    value={field.rush_cost}
                    onChange={handleChange}
                    required
                  />
                </Flex>
                <ButtonWrap
                  alignItems='center'
                  justifyContent='flex-end'
                  fullWidth
                >
                  <Input type='submit' value='Update' Button />
                  <CancelButton onClick={() => navigate('/')}>
                    Cancel
                  </CancelButton>
                </ButtonWrap>
              </Registration>
            </Form>
          </form>
        </Wrapper>
      </Container>
    </CommonLayout>
  )
}

export default CostPerHour

const CancelButton = styled(Button)`
  background-color: #dbdbdb;
  color: #333333;
  width: 100px;
  border-radius: 4px;
  &:hover {
    background: #e8eae6;
  }
`

const CreateButton = styled(Button)`
  width: 100px;
  border-radius: 4px;
`

const ButtonWrap = styled(Flex)`
  gap: 10px;
  margin-top: 10px;
`

const Registration = styled(Flex)`
  margin-top: 40px;
  gap: 10px;
`

const Form = styled(Flex)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%);
  width: 680px;
  height: auto;
  padding: 57px 65px;
`

const Wrapper = styled(Flex)`
  margin-top: 100px;
`
