import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { adminDashboardData } from "../../api/api";
import Container from "../../atoms/Container";
import Flex from "../../atoms/Flex";
import TableHeading from "../../atoms/TableHeading";
import CommonLayout from "../../layout/CommonLayout";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const fetchData = async () => {
    const resp = await adminDashboardData();
    setData(resp.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <CommonLayout>
      <Container>
        <Main fullWidth>
          <Flex fullWidth direction="column">
            <StyledTableHeading> Anlaytics</StyledTableHeading>
            <Flex direction="row" fullWidth style={{ display: "flex", gap: "10px", flexFlow: "wrap" }}>
              <Flex direction="column" className="mainCol">
                <SubHeading>Driver</SubHeading>
                <CardWrapper fullWidth>
                  <Card
                    direction="column"
                    onClick={() => navigate("/drivers")}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Title>Total Drivers</Title>
                    <Amount>{data?.noOfDriver}</Amount>
                  </Card>
                </CardWrapper>
              </Flex>
              <Flex direction="column" className="mainCol">
                <SubHeading>Hours Driven</SubHeading>
                <CardWrapper fullWidth>
                  <Card
                    direction="column"
                    onClick={() => navigate("/admin-timesheet")}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Title>Total Hours Driven</Title>
                    <Amount>{data?.noOfHoursDriven}</Amount>
                  </Card>
                </CardWrapper>
              </Flex>
            </Flex>
          </Flex>
          <Flex direction="column">
            <StyledTableHeading> Payment Anlaytics</StyledTableHeading>
            <Flex direction="row" fullWidth style={{ display: "flex", gap: "10px", flexFlow: "wrap" }}>
              <Flex direction="column" className="mainCol">
                <SubHeading>Total Paid Amount</SubHeading>
                <CardWrapper fullWidth>
                  <Card
                    direction="column"
                    onClick={() => navigate("/transactions")}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Title>Total Paid Transaction</Title>
                    <Amount>${data?.totalPaidPayment}</Amount>
                  </Card>
                </CardWrapper>
              </Flex>
              <Flex direction="column" className="mainCol">
                <SubHeading>Total Pending Amount</SubHeading>
                <CardWrapper fullWidth>
                  <Card
                    direction="column"
                    onClick={() => navigate("/transactions")}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Title>Total Pending Amount</Title>
                    <Amount>${data?.totalPendingPayment}</Amount>
                  </Card>
                </CardWrapper>
              </Flex>
              <Flex direction="column" className="mainCol">
                <SubHeading>Total Payment</SubHeading>
                <CardWrapper fullWidth>
                  <Card
                    direction="column"
                    onClick={() => navigate("/transactions")}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Title>Total Payment</Title>
                    <Amount>${data?.TotalPayment}</Amount>
                  </Card>
                </CardWrapper>
              </Flex>
            </Flex>
          </Flex>
        </Main>
      </Container>
    </CommonLayout>
  );
};

export default AdminDashboard;

const SubHeading = styled.div`
  margin-bottom: 10px;
`;

const CardWrapper = styled(Flex)`
  gap: 20px;
`;

const StyledTableHeading = styled(TableHeading)`
  margin-bottom: 25px;
  @media(max-width: 600px){
    font-size: 24px;
  }
`;

const Amount = styled.div`
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  padding: 0 16px;
  @media(max-width: 600px){
    font-size: 22px;
  }
`;

const Title = styled.div`
  color: #14141f;
  font-size: 20px;
  padding: 0 16px;
  @media(max-width: 600px){
    font-size: 18px;
  }
`;

const Card = styled(Flex)`
  min-width: 250px;
  height: 100px;
  background-color: #fff;
  border-radius: 8px;
  padding: 16px 0;
  gap: 16px;
  cursor: pointer;
  @media(max-width: 640px){
    min-width: 100%;
  }
`;

const Main = styled(Flex)`
  flex-wrap: wrap;
  margin-top: 50px;
  margin-bottom: 50px;
  gap: 25px;
  .mainCol{
    @media(max-width: 640px){
      width: 49%;
    }
    @media(max-width: 560px){
      width: 100%;
    }
  }
`;
