import React, { useState, useEffect } from "react";
import CommonLayout from "../../layout/CommonLayout";
import Container from "../../atoms/Container";
import styled from "styled-components";
import Flex from "../../atoms/Flex";
import Title from "../../atoms/Title";
import Label from "../../atoms/Label";
import Input from "../../atoms/Input";
import { CancelButton } from "../../atoms/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  createTimeSheet,
  getOneTimeSheet,
  updateTimesheet,
} from "../../api/api";
import { ErrorParser, SuccessParser } from "../../api/helper";

const CrateTimesheet = () => {
  const navigate = useNavigate();
  const initialField = {
    device_id: "",
    device_name: "",
    duration: "",
    begin_play_time: "",
    ad_name: "",
    location: {
      latitude: "",
      longitude: "",
    },
  };
  const [loading, setLoading] = useState(false);
  const [field, setField] = useState(initialField);
  const [isUpdate, setIsUpdate] = useState(false);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const resp = await getOneTimeSheet(id);
        setIsUpdate(true);
        handleSetUpdateState(resp.data);
      };
      fetchData();
    }
  }, [id]);

  const handleSetUpdateState = (data) => {
    Object.keys(initialField).forEach((key) => {
      if (key in data) {
        if (key === "location") {
          initialField[key].latitude = data.location.latitude;
          initialField[key].longitude = data.location.longitude;
        } else initialField[key] = data[key];
      }
    });
    setField({ ...initialField });
  };
  const handleChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      device_id: field.device_id,
      device_name: field.device_name,
      duration: parseInt(field.duration),
      begin_play_time: field.begin_play_time,
      ad_name: field.ad_name,
      location: {
        latitude: parseInt(field.location.latitude),
        longitude: parseInt(field.location.longitude),
      },
    };
    setLoading(true);
    try {
      const reqApi = isUpdate
        ? updateTimesheet(id, data)
        : createTimeSheet(data);
      const response = await reqApi;
      ErrorParser(response);
      if (response.status) {
        SuccessParser(isUpdate ? "TimeSheet Updated!" : "TimeSheet Created!");
        navigate("/admin-timesheet");
      }
    } catch (error) {
      console.log("Error ", error);
    }
    setLoading(false);
  };

  const handleLocation = (e) => {
    setField({
      ...field,
      location: {
        ...field.location,
        [e.target.name]: e.target.value,
      },
    });
  };
  if (loading) return null;
  return (
    <CommonLayout>
      <Container direction="column">
        <Wrapper alignItems="center" justifyContent="center" fullWidth>
          <form onSubmit={onSubmit}>
            <Form fullWidth direction="column">
              <Title>Time Sheet {isUpdate ? "Update" : "Create"}</Title>
              <Registration fullWidth direction="column">
                <Flex fullWidth direction="column">
                  <StyledFlex fullWidth>
                    <Flex direction="column" fullWidth>
                      <Label>Device ID</Label>
                      <Input
                        type="text"
                        placeholder="Enter Device Id"
                        name="device_id"
                        value={field.device_id}
                        onChange={handleChange}
                        required
                      />
                    </Flex>
                    <Flex direction="column" fullWidth>
                      <Label>Device Name</Label>
                      <Input
                        type="text"
                        placeholder="Enter Device Name"
                        name="device_name"
                        value={field.device_name}
                        onChange={handleChange}
                        required
                      />
                    </Flex>
                  </StyledFlex>
                  <Flex fullWidth direction="column">
                    <Label>Duration</Label>
                    <Input
                      type="number"
                      placeholder="Enter Duration"
                      name="duration"
                      value={field.duration}
                      onChange={handleChange}
                      required
                    />
                  </Flex>
                  <Flex fullWidth direction="column">
                    <StyledFlex fullWidth>
                      <Flex direction="column" fullWidth>
                        <Label>Play Time</Label>
                        <Input
                          type="datetime-local"
                          placeholder="Enter Played Time"
                          name="begin_play_time"
                          value={field.begin_play_time}
                          onChange={handleChange}
                          required
                        />
                      </Flex>
                      <Flex direction="column" fullWidth>
                        <Label>Ad Name</Label>
                        <Input
                          type="text"
                          placeholder="Enter Ad Name"
                          name="ad_name"
                          value={field.ad_name}
                          onChange={handleChange}
                          required
                        />
                      </Flex>
                    </StyledFlex>
                  </Flex>
                  <Flex fullWidth direction="column">
                    <StyledFlex fullWidth>
                      <Flex direction="column" fullWidth>
                        <Label>Latitude</Label>
                        <Input
                          type="text"
                          placeholder="Enter latitude"
                          name="latitude"
                          value={field.location.latitude}
                          onChange={handleLocation}
                          required
                        />
                      </Flex>
                      <Flex direction="column" fullWidth>
                        <Label>Longitude</Label>
                        <Input
                          type="text"
                          placeholder="Enter longitude"
                          name="longitude"
                          value={field.location.longitude}
                          onChange={handleLocation}
                          required
                        />
                      </Flex>
                    </StyledFlex>
                  </Flex>
                </Flex>
                <ButtonWrap
                  alignItems="center"
                  justifyContent="flex-end"
                  fullWidth
                >
                  <Input
                    type="submit"
                    value={isUpdate ? "Update" : "Create"}
                    Button
                    disabled={loading}
                  />
                  <CancelButton onClick={() => navigate("/admin-timesheet")}>
                    Cancel
                  </CancelButton>
                </ButtonWrap>
              </Registration>
            </Form>
          </form>
        </Wrapper>
      </Container>
    </CommonLayout>
  );
};

export default CrateTimesheet;

const ButtonWrap = styled(Flex)`
  gap: 10px;
  margin-top: 10px;
`;

const StyledFlex = styled(Flex)`
  gap: 10px;
`;

const Registration = styled(Flex)`
  margin-top: 40px;
  gap: 10px;
`;

const Wrapper = styled(Flex)`
  margin-top: 100px;
`;

const Form = styled(Flex)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%);
  width: 680px;
  height: auto;
  padding: 57px 65px;
`;
