import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { createDriver, getOneDriver, updateDriver } from '../../api/api'
import { CancelButton } from '../../atoms/Button'
import Container from '../../atoms/Container'
import Flex from '../../atoms/Flex'
import Input from '../../atoms/Input'
import Label from '../../atoms/Label'
import Title from '../../atoms/Title'
import CommonLayout from '../../layout/CommonLayout'
import CountryCode from '../login/CountryCode'
import { useSearchParams } from 'react-router-dom'
import { ErrorParser, SuccessParser } from '../../api/helper'

const Create = () => {
  const navigate = useNavigate()
  const initialField = {
    full_name: '',
    device_id: '',
    hourly_cost: '',
    country_code: 1,
    mobile_number: '',
  }
  const [field, setField] = useState(initialField)
  const [isUpdate, setIsUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        const resp = await getOneDriver(id)
        setIsUpdate(true)
        handleSetUpdateState(resp.data)
      }
      fetchData()
    }
  }, [id])

  const handleSetUpdateState = (data) => {
    Object.keys(initialField).forEach((key) => {
      if (key in data) {
        if (key === 'mobile_number') {
          initialField[key] = data.mobile_number.number
          initialField['country_code'] = data.mobile_number.country_code
        } else initialField[key] = data[key]
      }
    })
    setField({ ...initialField })
  }

  const handleChange = (e) => {
    setField({ ...field, [e.target.name]: e.target.value })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = {
      full_name: field.full_name,
      device_id: field.device_id,
      // hourly_cost: parseInt(field.hourly_cost),
      mobile_number: {
        country_code: parseInt(field.country_code),
        number: parseInt(field.mobile_number),
      },
    }
    setLoading(true)
    try {
      const reqApi = isUpdate ? updateDriver(id, data) : createDriver(data)
      const resp = await reqApi
      ErrorParser(resp)
      if (resp.status) {
        SuccessParser(isUpdate ? 'Driver Updated!' : 'Driver Created!')
        navigate('/drivers')
      }
    } catch (error) {
      console.log('Error ', error)
    }
    setLoading(false)
  }

  return (
    <CommonLayout>
      <Container direction='column'>
        <Wrapper alignItems='center' justifyContent='center' fullWidth>
          <form onSubmit={onSubmit} method='post'>
            <FormWrap fullWidth direction='column'>
              <Title>Driver {isUpdate ? 'Update' : 'Create '}</Title>
              <Registration fullWidth direction='column'>
                <Flex fullWidth direction='column'>
                  <Label>Full Name</Label>
                  <Input
                    type='text'
                    placeholder='Enter Full Name'
                    name='full_name'
                    value={field.full_name}
                    onChange={handleChange}
                    required
                  />
                </Flex>
                <Flex fullWidth direction='column'>
                  <StyledFlex fullWidth>
                    <Flex direction='column' fullWidth>
                      <Label>Device Id</Label>
                      <Input
                        type='text'
                        placeholder='Device ID'
                        name='device_id'
                        value={field.device_id}
                        onChange={handleChange}
                        required
                      />
                    </Flex>
                    {/* <Flex direction="column" fullWidth>
                      <Label>Hourly Cost</Label>
                      <Input
                        type="number"
                        placeholder="Hourly Cost"
                        name="hourly_cost"
                        value={field.hourly_cost}
                        onChange={handleChange}
                        required
                      />
                    </Flex> */}
                  </StyledFlex>
                </Flex>
                <Label>Phone Number</Label>
                <PhoneWrapper fullWidth direction='row'>
                  <Select name='country_code' onChange={handleChange} required>
                    <option>Code</option>
                    {CountryCode.map((item, key) => (
                      <option
                        key={key}
                        value={item.value}
                        selected={field.country_code == item.value}
                      >
                        + {item.value}
                      </option>
                    ))}
                  </Select>
                  <PhoneInput
                    type='Number'
                    placeholder='Phone Number'
                    name='mobile_number'
                    value={field.mobile_number}
                    onChange={handleChange}
                    required
                  />
                </PhoneWrapper>
                <ButtonWrap
                  alignItems='center'
                  justifyContent='flex-end'
                  fullWidth
                >
                  <Input
                    type='submit'
                    value={isUpdate ? 'Update' : 'Create'}
                    disabled={loading}
                    Button
                  />
                  <CancelButton onClick={() => navigate('/drivers')}>
                    Cancel
                  </CancelButton>
                </ButtonWrap>
              </Registration>
            </FormWrap>
          </form>
        </Wrapper>
      </Container>
    </CommonLayout>
  )
}

export default Create

const PhoneWrapper = styled(Flex)`
  gap: 10px;
`

const PhoneInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const ButtonWrap = styled(Flex)`
  gap: 10px;
  margin-top: 10px;
`

const StyledFlex = styled(Flex)`
  gap: 10px;
`

const Registration = styled(Flex)`
  margin-top: 40px;
  gap: 10px;
`

const FormWrap = styled(Flex)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 8px 20px 0 rgb(0 0 0 / 15%);
  width: 680px;
  height: auto;
  padding: 57px 65px;
`

const Wrapper = styled(Flex)`
  margin-top: 100px;
`

const Select = styled.select`
  border: none;
  cursor: pointer;
  height: 46px;
  background: #f2f2f2;
  border-radius: 4px;
`
