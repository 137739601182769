import Container from "../../atoms/Container";
import CommonLayout from "../../layout/CommonLayout";
import styled, { css } from "styled-components";
import Table from "../../atoms/Table";
import { useMemo, useState, useEffect } from "react";
import TimesheetHeader from "./Timesheet.table.head";
import { getTimeSheet, deleteTimeSheet } from "../../api/api";
import Button from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import Modal from "../../atoms/Modal";
import { ErrorParser, SuccessParser } from "../../api/helper";
import * as Icon from 'react-feather';
import Icons from "../../atoms/Icon";
import Flex from "../../atoms/Flex";

const Timesheet = () => {
  const [loading, setLoading] = useState(true);
  const [Open, setOpen] = useState(false);
  const [timeSheetName, setTimeSheetName] = useState(null);
  const [id, setId] = useState(null);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotalPage] = useState();
  const [limit, setLimit] = useState(25);
  const [datas, setData] = useState([]);
  const navigate = useNavigate();

  const nextPage = () => {
    getData(page + 1, limit);
    setCurrentPage(currentPage + 1);
  };

  const previousPage = () => {
    getData(page - 1, limit);
    setCurrentPage(currentPage - 1);
  };

  const gotoFirstPage = () => {
    getData(1, limit);
    setCurrentPage(1);
  };

  const gotoLastPage = () => {
    getData(total, limit);
    setCurrentPage(total);
  };

  const changeLimit = (limit) => {
    setLimit(limit);
    getData(page, limit);
  };

  const getData = async (page, limit) => {
    setPage(page);
    const resp = await getTimeSheet({ limit, page });
    setTotalPage(Math.ceil(resp.total / resp.perpage));
    let temp = [];
    if (resp.status) {
      temp.data = resp.data.map((el) => {
        return {
          device_id: el.device_id,
          ad_name: el.ad_name,
          logged_hours: el.duration,
          device_name: el.device_name,
          latitude: el.location.latitude,
          play_time: el.begin_play_time,
          id: el._id,
        };
      });
      temp = {
        ...temp,
        total: resp.total,
      };
      setData(temp);
      setLoading(false);
    }
  };
  useEffect(() => {
    getData(1, limit);
  }, []);

  const onClose = () => setOpen(false);

  const handleDelete = async () => {
    const resp = await deleteTimeSheet(id);
    ErrorParser(resp);
    if (!resp.error) {
      SuccessParser("TimeSheet Deleted!");
      getData(page, limit);
    }
    setOpen(false);
  };

  const handleOpen = (cell) => {
    setOpen(true);
    setId(cell.row.original.id);
    setTimeSheetName(cell.row.original.device_name);
  };
  const handleNavigate = (cell) => {
    navigate({
      pathname: "/admin-timesheet/create",
      search: `?id=${cell.row.original.id}`,
    });
  };
  const columns = useMemo(
    () => [
      {
        accessor: "device_id",
        Header: "Device ID",
      },
      {
        accessor: "device_name",
        Header: "Device Name",
      },
      {
        accessor: "logged_hours",
        Header: "Logged Seconds",
      },
      {
        accessor: "play_time",
        Header: "Play Time",
      },
      {
        accessor: "ad_name",
        Header: "AD Name",
      },
      {
        accessor: "latitude",
        Header: "Latitude",
      },
      {
        accessor: "Action",
        Header: "Action",
        Cell: ({ cell }) => (
          <>
            <Flex direction="row" style={{gap:"10px", align:"center", justifyContent:"space-evenly"}}>
              <Icons>
                <Icon.Edit2
                  value={cell.row.values.name}
                  onClick={() => handleNavigate(cell)}
                />
              </Icons>
              <Icons>
                <Icon.Trash2
                  value={cell.row.values.name}
                  onClick={() => handleOpen(cell)}
                />
              </Icons>
            </Flex>
          </>
        ),
      },
    ],
    []
  );

  if (loading) return null;
  return (
    <>
      <CommonLayout>
        <StyledContainer>
          <Table
            data={datas}
            columns={columns}
            Header={TimesheetHeader}
            HeaderProps={{ getData }}
            hasFileUpload
            pageCount={page}
            currentPage={currentPage}
            totalPage={total}
            previousPage={previousPage}
            gotoLastPage={gotoLastPage}
            gotoFirstPage={gotoFirstPage}
            limit={limit}
            nextPage={nextPage}
            changeLimit={changeLimit}
            dataPerPage={[25, 50, 100, 200]}
          />
        </StyledContainer>
      </CommonLayout>
      <Modal
        onClose={onClose}
        onClick={handleDelete}
        Open={Open}
        title="Delete TimeSheet"
        subTitle={`Are you sure you want to delete this ${timeSheetName}?`}
        btnName="Delete"
      />
    </>
  );
};

export default Timesheet;

const StyledButton = styled(Button)`
  border-radius: 4px;
  width: 100px;
  ${({ left }) =>
    left &&
    css`
      margin-left: 10px;
    `}
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;


// export default function Appp()  {
//   return(
//     <div>
//       <input type="file" />
//       <button>jhjkhjk</button>
//     </div>
//   )
// }